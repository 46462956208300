// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cra-mdx": () => import("./../../../src/pages/cra.mdx" /* webpackChunkName: "component---src-pages-cra-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-refs-workshop-mdx": () => import("./../../../src/pages/refs-workshop.mdx" /* webpackChunkName: "component---src-pages-refs-workshop-mdx" */),
  "component---src-pages-rle-mdx": () => import("./../../../src/pages/rle.mdx" /* webpackChunkName: "component---src-pages-rle-mdx" */),
  "component---src-pages-testing-course-mdx": () => import("./../../../src/pages/testing-course.mdx" /* webpackChunkName: "component---src-pages-testing-course-mdx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

